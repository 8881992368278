import useRequest, { CommonResponse } from "./../useRequest";
import { computed } from "vue";

const useTransferType = () => {
  const { data, ...other } = useRequest<CommonResponse<any>>(
    `/app/enum/transfer-type`
  );
  const enhancedData = computed(() => {
    if (!data.value) return null;
    return data.value.data.data;
  });

  return {
    transferType: enhancedData,
    ...other
  };
};

export default useTransferType;
